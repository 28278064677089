// src/Header.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Menu, MenuItem } from '@mui/material';

const HeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  letter-spacing: 0.24em;
  padding: 10px 3px;
  position: relative;
  padding-bottom: 30px;

  @media (min-width: 769px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 10px 6px;
    height: 60px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 20px;
  width: 100%;
  //max-width: 70%;
  
  @media (min-width: 769px) {
    justify-content: flex-start;
    align-items: flex-end;
  }
`;

const Logo = styled.h1`
  font-size: 41px;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  margin-bottom: 0;
  letter-spacing: 0.24em;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 768px) {
    font-size: 30px;
    text-align: center;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 0;
  }
`;

const Nav = styled.nav`
  display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
  flex-direction: column;
  gap: 10px;
  width: 100%;
  align-items: center;
  text-transform: uppercase;

  @media (min-width: 769px) {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: flex-end;
    align-items: flex-end;
    flex: 1;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;

  &:hover {
    color: #0073e6;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  text-decoration: none;
  color: black;

  &:hover {
    color: #0073e6;
    background-color: transparent;
  }
`;

const MenuToggle = styled.button`
  display: none;
  background: none;
  border: none;
  color: black;
  font-size: 18px;
  text-transform: uppercase;
  margin-top: 10px;

  @media (max-width: 768px) {
    display: block;
    margin-top: 0;
    margin-bottom: 10px;
  }
`;

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <HeaderContainer>
      <MenuToggle onClick={toggleMenu}>MENU</MenuToggle>

      <LogoContainer>
        <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>
          <Logo>Footage-Now</Logo>
        </Link>
      </LogoContainer>

      <Nav $isOpen={isMenuOpen}>
        <StyledLink to="/">Accueil</StyledLink>
        <StyledLink to="/gallery">Photographies</StyledLink>
        <StyledLink to="/video">Vidéos</StyledLink>

        <div>
          <StyledLink
            to="#"
            aria-controls="prestations-menu"
            aria-haspopup="true"
            onClick={handleMenuOpen}
          >
            Prestations
          </StyledLink>
          <Menu
            id="prestations-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            MenuListProps={{ disablePadding: true }}
          >
            <StyledMenuItem onClick={handleMenuClose}>
              <StyledLink to="/photographe-voyage-tourisme">
                Photographe de Voyage & Tourisme
              </StyledLink>
            </StyledMenuItem>
            <StyledMenuItem onClick={handleMenuClose}>
              <StyledLink to="/video-cinematographique">
                Vidéo Cinématographique de Voyage & Tourisme
              </StyledLink>
            </StyledMenuItem>
            <StyledMenuItem onClick={handleMenuClose}>
              <StyledLink to="/prestation-drone-stabilise">
                Prestation Drone Stabilisé
              </StyledLink>
            </StyledMenuItem>
            <StyledMenuItem onClick={handleMenuClose}>
              <StyledLink to="/prestation-drone-fpv">
                Prestation Drone FPV
              </StyledLink>
            </StyledMenuItem>
            <StyledMenuItem onClick={handleMenuClose}>
              <StyledLink to="/vente-rush-videos-photos">
                Vente de rush vidéos et Photos
              </StyledLink>
            </StyledMenuItem>
          </Menu>
        </div>

        <StyledLink to="/contact">Contact</StyledLink>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;
