import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../auth';
import Maintenance from './Maintenance';
import { getSettings } from '../api';

const MaintenanceWrapper = ({ children }) => {
  const [isMaintenance, setIsMaintenance] = useState(false);
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  useEffect(() => {
    const checkMaintenanceMode = async () => {
      try {
        const response = await getSettings();
        if (response.data.success) {
          if (response.data.settings.maintenance && location.pathname !== '/admin/login') {
            setIsMaintenance(true);
          } else {
            setIsMaintenance(false);
          }
        }
      } catch (error) {
        console.error('Error checking maintenance mode', error);
      }
    };
    checkMaintenanceMode();
  }, []);

  if (isMaintenance && !isAuthenticated) {
    return <Maintenance />;
  }

  return <>{children}</>;
};

export default MaintenanceWrapper;