// src/Index.js
import React from 'react';
import { Container, Grid, Typography, Card, CardContent, CardMedia, Button, Divider, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const Index = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box textAlign="center" mb={4}>
        <Typography
          variant="h3"
          component="h1"
          gutterBottom
          className="h1-no-margin"
        >
          Vidéaste et Photographe Professionnel
        </Typography>
        
        <Typography
          variant="h3"
          component="h1"
          gutterBottom
          className="h1-no-margin"
        >
          de Voyage & Tourisme
        </Typography>        
        
        <Typography variant="h5" component="h2" gutterBottom>
          Capturer l'Essence de Chaque Destination
        </Typography>
        <Typography variant="body1" paragraph>
          Promouvez votre destination touristique ou agence de voyage avec un vidéaste et photographe professionnel.
        </Typography>
      </Box>

      <Divider />

      <Box textAlign="center" my={4}>
        <Typography variant="h5" component="h2">
          Bienvenue sur <strong>Footage-Now</strong>, le site d'un vidéaste et photographe professionnel passionné par les voyages et la découverte de nouveaux horizons.
        </Typography>
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardMedia
              component="img"
              height="200"
              image="/assets/images/iceland.jpg"
              alt="Photographie de Voyage"
            />
            <CardContent>
              <Typography variant="h5" component="h3">
                Photographie de Voyage
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Immortalisez des paysages époustouflants et des moments authentiques à travers des images qui racontent une histoire unique.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardMedia
              component="img"
              height="200"
              image="/assets/images/captation.jpg"
              alt="Vidéographie de Tourisme"
            />
            <CardContent>
              <Typography variant="h5" component="h3">
                Vidéographie de Tourisme
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Créez des vidéos immersives et cinématographiques qui mettent en valeur chaque destination de manière captivante.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Divider sx={{ my: 4 }} />

      <Typography variant="h5" component="h2" align="center" gutterBottom>
        Pourquoi Me Choisir ?
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="h3">
                Pour les Agences de Voyage
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Fournissez des contenus visuels inspirants qui attirent l'attention de vos futurs clients.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="h3">
                Pour les Offices de Tourisme
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Promouvez vos destinations avec des images et vidéos qui capturent l'essence et la beauté de chaque lieu.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Divider sx={{ my: 4 }} />

      <Typography variant="h5" component="h2" align="center" gutterBottom>
        Services Offerts
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Card>
            <CardMedia
              component="img"
              height="200"
              image="/assets/images/reunion.jpg"
              alt="Photographie de Paysage"
            />
            <CardContent>
              <Typography variant="h5" component="h3">
                Photographie de Paysage
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Capturez la splendeur naturelle de chaque destination avec des images de haute qualité.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardMedia
              component="img"
              height="200"
              image="/assets/images/keiro_iceland.jpg"
              alt="Vidéographie Cinématographique"
            />
            <CardContent>
              <Typography variant="h5" component="h3">
                Vidéographie Cinématographique
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Offrez une expérience immersive grâce à des vidéos professionnelles qui plongent vos spectateurs au cœur de l'action.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardMedia
              component="img"
              height="200"
              image="/assets/images/dji-air-3.png"
              alt="Prises de Vue par Drone"
            />
            <CardContent>
              <Typography variant="h5" component="h3">
                Prises de Vue par Drone
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Ajoutez une perspective aérienne unique avec des drones FPV et stabilisés pour des vues imprenables.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Divider sx={{ my: 4 }} />

      <Typography variant="h5" component="h2" align="center" gutterBottom>
        Ma Philosophie
      </Typography>
      <Typography variant="body1" paragraph align="center">
        Grâce à ma passion pour les voyages et mon expertise en photographie et vidéographie, je transforme chaque lieu en un récit visuel fascinant. Je m'engage à vous fournir des images qui non seulement racontent une histoire mais suscitent également l'émotion et l'émerveillement.
      </Typography>

      <Divider sx={{ my: 4 }} />

      <Typography variant="h5" component="h2" align="center" gutterBottom>
        Mes Atouts
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="h3">
                Expérience et Compétence
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Des années d’expérience dans la capture d'images à travers le monde.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="h3">
                Créativité et Innovation
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Des techniques modernes et des équipements à la pointe de la technologie pour des résultats impressionnants.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="h3">
                Flexibilité et Adaptabilité
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Disponibilité pour voyager et couvrir des destinations variées selon vos besoins.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Divider sx={{ my: 4 }} />

      <Box textAlign="center" my={4}>
        <Typography variant="h5" component="h2" gutterBottom>
          Contactez-moi
        </Typography>
        <Typography variant="body1" paragraph>
          Contactez-moi dès aujourd'hui pour discuter de vos projets et voir comment mes services peuvent vous aider à atteindre vos objectifs. Ensemble, nous créerons des souvenirs visuels qui inciteront à l'exploration et à l'évasion.
        </Typography>
        <Button variant="contained" color="primary" component={Link} to="/contact">
          Me Contacter
        </Button>
      </Box>

      <Divider sx={{ my: 4 }} />

      <Typography variant="h5" component="h2" align="center">
        Laissez-vous transporter par mes images et vidéos. Avec mon expertise, chaque destination devient une aventure visuelle inoubliable.
      </Typography>

      <Typography variant="h6" component="h3" align="center" gutterBottom sx={{ mt: 4 }}>
        Footage-Now - Capturer l'instant, inspirer le voyage.
      </Typography>
    </Container>
  );
};

export default Index;
