import React from 'react';
import { Container, Grid, Typography, Card, CardContent, CardMedia, Button, Divider, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const PrestaPhoto = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {/* Section d'introduction */}
      <Box textAlign="center" mb={4}>
        <Typography variant="h3" component="h1" gutterBottom className="h1-no-margin">
          Photographe de Voyage & Tourisme
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Des Éblouissantes Escapades Capturées par un Objectif Professionnel
        </Typography>
        <Typography variant="body1" paragraph>
          Bienvenue sur <strong>Footage-Now</strong>, votre destination pour des photographies de voyage et de tourisme captivantes. En tant qu'agence de voyage ou office de tourisme, vous comprenez l'impact puissant des visuels sur les décisions de voyage.
        </Typography>
      </Box>

      <Divider />

      {/* Pourquoi choisir */}
      <Box textAlign="center" my={4}>
        <Typography variant="h5" component="h2">
          Pourquoi Choisir un Photographe de Voyage et Tourisme ?
        </Typography>
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardContent>
              <Typography variant="h5" component="h3">
                Mettent en Valeur la Beauté des Lieux
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Chaque cliché capture l’essence et l’atmosphère unique de chaque destination, inspirant les voyageurs à explorer de nouveaux horizons.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardContent>
              <Typography variant="h5" component="h3">
                Renforcent la Crédibilité
              </Typography>
              <Typography variant="body2" color="textSecondary">
                En montrant des visuels de haute qualité, vous renforcez votre crédibilité et attirez un public plus large.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardContent>
              <Typography variant="h5" component="h3">
                Suscitent l’Enthousiasme
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Des images inspirantes stimulent l’envie de voyager et rendent vos offres plus attractives.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Divider sx={{ my: 4 }} />

      {/* Notre Expertise */}
      <Typography variant="h5" component="h2" align="center" gutterBottom>
        Mon Expertise
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardMedia
              component="img"
              height="200"
              image="/assets/images/prague.jpg"
              alt="Photographie de Paysage et d’Architecture"
            />
            <CardContent>
              <Typography variant="h5" component="h3">
                Photographie de Paysage et d’Architecture
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Capturez la grandeur naturelle et la splendeur architecturale de chaque destination.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardMedia
              component="img"
              height="200"
              image="/assets/images/borabora-pareo.jpg"
              alt="Photographie Culturelle"
            />
            <CardContent>
              <Typography variant="h5" component="h3">
                Photographie Culturelle
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Documentez les coutumes et les modes de vie locaux pour offrir une expérience immersive.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardMedia
              component="img"
              height="200"
              image="/assets/images/dubrovnik.jpg"
              alt="Photographie Aérienne"
            />
            <CardContent>
              <Typography variant="h5" component="h3">
                Photographie Aérienne
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Utilisez des prises de vue par drone pour révéler des perspectives uniques et spectaculaires.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Divider sx={{ my: 4 }} />

      {/* Votre Projet */}
      <Typography variant="h5" component="h2" align="center" gutterBottom>
        Votre Projet, Ma Mission
      </Typography>
      <Typography variant="body1" paragraph align="center">
        Que vous ayez besoin de photos pour des brochures, des sites web ou des campagnes de médias sociaux, nous vous aidons à créer des visuels qui captivent et inspirent.
      </Typography>
      <Typography variant="body1" paragraph align="center">
        Contactez-nous dès aujourd'hui pour discuter de votre projet et découvrir comment nous pouvons vous aider à transformer chaque destination en une aventure visuelle inoubliable.
      </Typography>

      <Divider sx={{ my: 4 }} />

      {/* Boostez votre Visibilité */}
      <Typography variant="h5" component="h2" align="center" gutterBottom>
        Boostez votre Visibilité et Attirez de Nouveaux Clients !
      </Typography>
      <Typography variant="body1" paragraph align="center">
        En investissant dans des photographies professionnelles de voyage et de tourisme, vous augmentez la visibilité de vos destinations et créez un attrait irrésistible pour les voyageurs. Ensemble, nous ferons de chaque image une invitation à l'exploration.
      </Typography>
      <Typography variant="body1" paragraph align="center">
        <strong>Footage-Now</strong> - Votre partenaire pour des photographies de voyage et de tourisme exceptionnelles.
      </Typography>

      <Divider sx={{ my: 4 }} />

      {/* Contact */}
      <Box textAlign="center" my={4}>
        <Typography variant="h5" component="h2" gutterBottom>
          Contactez-nous
        </Typography>
        <Typography variant="body1" paragraph>
          Contactez-nous dès aujourd'hui pour discuter de votre projet et découvrir comment nous pouvons vous aider à transformer chaque destination en une aventure visuelle inoubliable.
        </Typography>
        <Button variant="contained" color="primary" component={Link} to="/contact">
          Nous Contacter
        </Button>
      </Box>

      <Divider sx={{ my: 4 }} />

      <Typography variant="h6" component="h3" align="center" gutterBottom>
        Footage-Now - Capturer l'instant, inspirer le voyage.
      </Typography>
    </Container>
  );
};

export default PrestaPhoto;
