// src/components/Contact.js
import React from 'react';
import styled from 'styled-components';

const ContactContainer = styled.div`
  display: flex;
  padding: 20px 0px;
  font-family: 'Atrament-web', sans-serif;
`;

const ContactImage = styled.img`
  width: 300px;
  height: auto;
  margin-right: 20px;
`;

const ContactText = styled.div`
  max-width: 600px;
`;

const Contact = () => {
  return (
    <ContactContainer>
      <ContactImage src="/assets/images/sebastien_fantinati.jpg" alt="Sebastien Fantinati" />
      <ContactText>
<h2>Sébastien Fantinati</h2>
        <p>Je suis un photographe et vidéaste spécialisé dans les récits de style de vie et de voyage et je suis disponible pour des projets commerciaux dans le monde entier.</p>
        <p>Je passe la majorité de mon temps à produire du contenu photographique et vidéo en créant des histoires visuelles lors de mes prestations ou de mes voyage à travers le monde.</p >
        <p>Si vous souhaitez discuter d'un nouveau projet, d'une licence d'image ou pour toute autre demande, veuillez nous contacter par e-mail :</p>
        <p>projet@footage-now.video</p>

      </ContactText>
    </ContactContainer>
  );
};

export default Contact;
