import React, { useState, useEffect } from 'react';
import { getYouTubeLinks } from '../api';
import { Box, Typography, Paper, Grid } from '@mui/material';

const Video = () => {
  const [videos, setVideos] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await getYouTubeLinks();
        if (response.data.success && Array.isArray(response.data.links)) {
          setVideos(response.data.links);
        } else {
          console.error('Unexpected API response:', response.data);
          setErrorMessage('Error fetching YouTube videos.');
        }
      } catch (error) {
        console.error('Error fetching YouTube videos:', error);
        setErrorMessage('Error fetching YouTube videos.');
      }
    };
    fetchVideos();
  }, []);

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h5" component="h2" gutterBottom>
        Dernier Showreel
      </Typography>
      <Paper elevation={3} sx={{ padding: 2, marginBottom: 4 }}>
        <iframe
          width="100%"
          height="500"
          src="https://www.youtube.com/embed/luIV8X43954"
          title="Dernier Showreel"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Paper>
      <Typography variant="h5" component="h2" gutterBottom>
        Vidéos Cinématographique
      </Typography>
      {errorMessage && (
        <Typography variant="body1" color="error" gutterBottom>
          {errorMessage}
        </Typography>
      )}
      <Grid container spacing={3}>
        {videos.map((video, index) => (
          <Grid item xs={12} md={6} lg={4} key={index}>
            <Paper elevation={3} sx={{ padding: 2 }}>
              <iframe
                width="100%"
                height="315"
                src={`https://www.youtube.com/embed/${video.link}`}
                title={`YouTube video ${index}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Video;
