import axios from 'axios';
const config = require('./config.json');

const api = axios.create({
  baseURL: `${config.serverIP}`,
  withCredentials: true
});

// Ajouter un interceptor pour inclure le JWT dans les requ�tes
api.interceptors.request.use((request) => {
  const token = localStorage.getItem('token'); // Supposons que le token soit stock� dans localStorage
  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }
  return request;
});

// Authentication related functions
export const login = async (username, password) => {
  const response = await api.post('/login', { username, password });
  if (response.data.token) {
    localStorage.setItem('token', response.data.token); // Stocker le token dans localStorage
  }
  return response;
};

export const logout = () => {
  localStorage.removeItem('token'); // Supprimer le token lors de la d�connexion
  return api.post('/logout');
};

// Image management functions
export const uploadImage = (formData) => api.post('/upload', formData);

// Get all images
export const getImages = async () => {
  try {
    const response = await api.get('/images');
    const imagesWithThumbUrl = response.data.map(image => ({
      name: image.name,
      thumb: `${image.thumb}`
    }));
    return imagesWithThumbUrl;
  } catch (error) {
    console.error("Error getting images", error);
    throw error;
  }
};

// Delete an image
export const deleteImage = async (imageName) => {
  try {
    const response = await api.delete(`/images/${imageName}`);
    return response;
  } catch (error) {
    console.error('Error deleting image:', error.response ? error.response.data : error.message);
    throw error;
  }
};

// User account management functions
export const changePassword = async (oldPassword, newPassword) => {
  try {
    const response = await api.put('/change-password', { oldPassword, newPassword });
    return response;
  } catch (error) {
    console.error('Error changing password:', error.response ? error.response.data : error.message);
    throw error;
  }
};

// YouTube link management functions
export const addYouTubeLink = async (name, link) => {
  try {
    const response = await api.post('/youtube-links', { name, link });
    return response;
  } catch (error) {
    console.error('Error adding YouTube link:', error.response ? error.response.data : error.message);
    throw error;
  }
};

// Get all YouTube links
export const getYouTubeLinks = async () => {
  try {
    const response = await api.get('/youtube-links');
    return response;
  } catch (error) {
    console.error('Error getting YouTube links:', error.response ? error.response.data : error.message);
    throw error;
  }
};

// Delete a YouTube link
export const deleteYouTubeLink = async (linkId) => {
  try {
    const response = await api.delete(`/youtube-links/${linkId}`);
    return response;
  } catch (error) {
    console.error('Error deleting YouTube link:', error.response ? error.response.data : error.message);
    throw error;
  }
};

// Site settings management functions
export const getSettings = async () => {
  try {
    const response = await api.get('/settings');
    return response;
  } catch (error) {
    console.error('Error getting settings:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const updateSettings = async (settings) => {
  try {
    const response = await api.put('/settings', settings);
    return response;
  } catch (error) {
    console.error('Error updating settings:', error.response ? error.response.data : error.message);
    throw error;
  }
};

// Client management functions
export const createClient = async (login, password) => {
  try {
    const response = await api.post('/clients', { login, password });
    return response;
  } catch (error) {
    console.error('Error creating client:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const getClients = async () => {
  try {
    const response = await api.get('/clients');
    return response.data;
  } catch (error) {
    console.error('Error getting clients:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const deleteClient = async (clientId) => {
  try {
    const response = await api.delete(`/clients/${clientId}`);
    return response;
  } catch (error) {
    console.error('Error deleting client:', error.response ? error.response.data : error.message);
    throw error;
  }
};

// Client gallery management functions
export const createClientGallery = async (clientId, galleryName) => {
  try {
    const response = await api.post(`/clients/${clientId}/galleries`, { galleryName });
    return response;
  } catch (error) {
    console.error('Error creating client gallery:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const getClientGalleries = async (clientId) => {
  try {
    const response = await api.get(`/clients/${clientId}/galleries`);
    return response.data;
  } catch (error) {
    console.error('Error getting client galleries:', error.response ? error.response.data : error.message);
    throw error;
  }
};

// Category management functions
export const createCategory = async (name) => {
  try {
    const response = await api.post('/categories', { name });
    return response;
  } catch (error) {
    console.error('Error creating category:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const getCategories = async () => {
  try {
    const response = await api.get('/categories');
    return response.data;
  } catch (error) {
    console.error('Error getting categories:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const deleteCategory = async (categoryId) => {
  try {
    const response = await api.delete(`/categories/${categoryId}`);
    return response;
  } catch (error) {
    console.error('Error deleting category:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const updateCategory = async (categoryId, categoryData) => {
  try {
    const response = await api.put(`/categories/${categoryId}`, categoryData);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la mise � jour de la cat�gorie:', error.response ? error.response.data : error.message);
    throw error;
  }
};

// Under-category management functions
export const createUnderCategory = async (name, categoryId) => {
  try {
    const response = await api.post('/under-categories', { name, id_category: categoryId });
    return response;
  } catch (error) {
    console.error('Error creating under-category:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const getUnderCategories = async () => {
  try {
    const response = await api.get('/under-categories');
    return response.data;
  } catch (error) {
    console.error('Error getting under-categories:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const updateUnderCategory = async (underCategoryId, underCategoryData) => {
  try {
    const response = await api.put(`/under-categories/${underCategoryId}`, underCategoryData);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la mise � jour de la sous-cat�gorie:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const deleteUnderCategory = async (underCategoryId) => {
  try {
    const response = await api.delete(`/under-categories/${underCategoryId}`);
    return response;
  } catch (error) {
    console.error('Error deleting under-category:', error.response ? error.response.data : error.message);
    throw error;
  }
};

