// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Index from './components/Index';
import Gallery from './components/Gallery';
import Footer from './components/Footer';
import Contact from './components/Contact';
import Login from './components/Login';
import Admin from './components/Admin';
import Account from './components/Account';
import Youtube from './components/Youtube';
import Video from './components/Video';
import Settings from './components/Settings';
import PrestaPhoto from './components/PrestaPhoto';
import PrestaVideo from './components/PrestaVideo';
import PrestaDrone from './components/PrestaDrone';
import PrestaFpv from './components/PrestaFpv';
import VenteRush from './components/VenteRush';
import CategoryAdmin from './components/Category';
import MaintenanceWrapper from './components/MaintenanceWrapper';
import { AuthProvider, useAuth } from './auth';
import { initGA, logPageView } from './analytics'; // Importez vos fonctions d'analytics

const TrackPage = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView(location.pathname); // Enregistrer une vue de page � chaque changement de route
  }, [location]);

  return null;
};

const App = () => {
  useEffect(() => {
    initGA(); // Initialiser Google Analytics lors du montage du composant
  }, []);

  return (
    <AuthProvider>
      <Router>
        <MaintenanceWrapper>
          <TrackPage /> {/* Ajoutez le composant de suivi des pages */}
          <Header />
          <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/admin/login" element={<Login />} />
            <Route path="/admin/index" element={<PrivateRoute component={<Admin />} />} />
            <Route path="/admin/account" element={<PrivateRoute component={<Account />} />} />
            <Route path="/admin/youtube" element={<PrivateRoute component={<Youtube />} />} />
            <Route path="/admin/settings" element={<PrivateRoute component={<Settings />} />} />
            <Route path="/admin/categories" element={<PrivateRoute component={<CategoryAdmin />} />} />
            
            <Route path="/contact" element={<Contact />} />
            <Route path="/video" element={<Video />} />
            <Route path="/photographe-voyage-tourisme" element={<PrestaPhoto />} />
            <Route path="/video-cinematographique" element={<PrestaVideo />} />
            <Route path="/prestation-drone-stabilise" element={<PrestaDrone />} />
            <Route path="/prestation-drone-fpv" element={<PrestaFpv />} />
            <Route path="/vente-rush-videos-photos" element={<VenteRush />} />
            <Route path="/Gallery" element={<Gallery />} />
          </Routes>
          <Footer />
        </MaintenanceWrapper>
      </Router>
    </AuthProvider>
  );
};

const PrivateRoute = ({ component }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? component : <Navigate to="/admin/login" />;
};

export default App;