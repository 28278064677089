import React, { useState, useEffect } from 'react';
import {
  createCategory,
  getCategories,
  deleteCategory,
  updateCategory,
  createUnderCategory,
  getUnderCategories,
  deleteUnderCategory,
  updateUnderCategory
} from '../api';

import {
  Box,
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const CategorieAdmin = () => {
  const [categories, setCategories] = useState([]);
  const [underCategories, setUnderCategories] = useState([]);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [editCategoryName, setEditCategoryName] = useState('');
  const [categoryToEdit, setCategoryToEdit] = useState(null);
  const [newUnderCategoryName, setNewUnderCategoryName] = useState('');
  const [editUnderCategoryName, setEditUnderCategoryName] = useState('');
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [underCategoryToEdit, setUnderCategoryToEdit] = useState(null);

  useEffect(() => {
    fetchCategories();
    fetchUnderCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const data = await getCategories();
      setCategories(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des catégories:', error);
    }
  };

  const fetchUnderCategories = async () => {
    try {
      const data = await getUnderCategories();
      setUnderCategories(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des sous-catégories:', error);
    }
  };

  const handleCreateCategory = async () => {
    if (newCategoryName.trim() === '') {
      alert('Le nom de la catégorie ne peut pas être vide');
      return;
    }

    try {
      await createCategory(newCategoryName);
      setNewCategoryName('');
      fetchCategories();
    } catch (error) {
      console.error('Erreur lors de la création de la catégorie:', error);
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette catégorie ?')) {
      try {
        await deleteCategory(categoryId);
        fetchCategories();
        fetchUnderCategories();
      } catch (error) {
        console.error('Erreur lors de la suppression de la catégorie:', error);
      }
    }
  };

  const handleEditCategory = (category) => {
    setEditCategoryName(category.name);
    setCategoryToEdit(category);
  };

  const handleUpdateCategory = async () => {
    if (!categoryToEdit || editCategoryName.trim() === '') {
      alert('Le nom de la catégorie ne peut pas être vide');
      return;
    }

    try {
      await updateCategory(categoryToEdit.id, editCategoryName);
      setCategoryToEdit(null);
      setEditCategoryName('');
      fetchCategories();
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la catégorie:', error);
    }
  };

  const handleCreateUnderCategory = async () => {
    if (newUnderCategoryName.trim() === '' || !selectedCategoryId) {
      alert('Le nom de la sous-catégorie ne peut pas être vide et une catégorie doit être sélectionnée');
      return;
    }

    try {
      await createUnderCategory(newUnderCategoryName, selectedCategoryId);
      setNewUnderCategoryName('');
      setSelectedCategoryId('');
      fetchUnderCategories();
    } catch (error) {
      console.error('Erreur lors de la création de la sous-catégorie:', error);
    }
  };

  const handleDeleteUnderCategory = async (underCategoryId) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette sous-catégorie ?')) {
      try {
        await deleteUnderCategory(underCategoryId);
        fetchUnderCategories();
      } catch (error) {
        console.error('Erreur lors de la suppression de la sous-catégorie:', error);
      }
    }
  };

  const handleEditUnderCategory = (underCategory) => {
    setEditUnderCategoryName(underCategory.name);
    setUnderCategoryToEdit(underCategory);
    setSelectedCategoryId(underCategory.category_id);
  };

  const handleUpdateUnderCategory = async () => {
    if (!underCategoryToEdit || editUnderCategoryName.trim() === '' || !selectedCategoryId) {
      alert('Le nom de la sous-catégorie ne peut pas être vide et une catégorie doit être sélectionnée');
      return;
    }

    try {
      await updateUnderCategory(underCategoryToEdit.id, editUnderCategoryName, selectedCategoryId);
      setUnderCategoryToEdit(null);
      setEditUnderCategoryName('');
      setSelectedCategoryId('');
      fetchUnderCategories();
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la sous-catégorie:', error);
    }
  };

  return (

    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" component="h2" gutterBottom>
        Administration des Catégories
      </Typography>

      {/* Gestion des catégories */}
      <Box sx={{ marginBottom: 4 }}>
        <Typography variant="h6" component="h3">
          Créer une Nouvelle Catégorie
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: 2 }}>
          <TextField
            label="Nom de la Catégorie"
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
            variant="outlined"
            fullWidth
          />
          <Button variant="contained" color="primary" onClick={handleCreateCategory}>
            Créer Catégorie
          </Button>
        </Box>
      </Box>

      {categoryToEdit && (
        <Box sx={{ marginBottom: 4 }}>
          <Typography variant="h6" component="h3">
            Modifier la Catégorie
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: 2 }}>
            <TextField
              label="Nom de la Catégorie"
              value={editCategoryName}
              onChange={(e) => setEditCategoryName(e.target.value)}
              variant="outlined"
              fullWidth
            />
            <Button variant="contained" color="primary" onClick={handleUpdateCategory}>
              Mettre à Jour
            </Button>
            <Button variant="outlined" color="secondary" onClick={() => setCategoryToEdit(null)}>
              Annuler
            </Button>
          </Box>
        </Box>
      )}

      <Box sx={{ marginBottom: 4 }}>
        <Typography variant="h6" component="h3">
          Catégories Existantes
        </Typography>
        <List>
          {categories.map((category) => (
            <ListItem key={category.id} divider>
              <ListItemText primary={category.name} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="edit"
                  color="primary"
                  onClick={() => handleEditCategory(category)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  color="secondary"
                  onClick={() => handleDeleteCategory(category.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Box>

      {/* Gestion des sous-catégories */}
      <Box sx={{ marginBottom: 4 }}>
        <Typography variant="h6" component="h3">
          Créer une Nouvelle Sous-Catégorie
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: 2 }}>
          <TextField
            label="Nom de la Sous-Catégorie"
            value={newUnderCategoryName}
            onChange={(e) => setNewUnderCategoryName(e.target.value)}
            variant="outlined"
            fullWidth
          />
          <FormControl fullWidth variant="outlined">
            <InputLabel>Catégorie</InputLabel>
            <Select
              value={selectedCategoryId}
              onChange={(e) => setSelectedCategoryId(e.target.value)}
              label="Catégorie"
            >
              <MenuItem value="">
                <em>Sélectionner une Catégorie</em>
              </MenuItem>
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button variant="contained" color="primary" onClick={handleCreateUnderCategory}>
            Créer Sous-Catégorie
          </Button>
        </Box>
      </Box>

      {underCategoryToEdit && (
        <Box sx={{ marginBottom: 4 }}>
          <Typography variant="h6" component="h3">
            Modifier la Sous-Catégorie
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: 2 }}>
            <TextField
              label="Nom de la Sous-Catégorie"
              value={editUnderCategoryName}
              onChange={(e) => setEditUnderCategoryName(e.target.value)}
              variant="outlined"
              fullWidth
            />
            <FormControl fullWidth variant="outlined">
              <InputLabel>Catégorie</InputLabel>
              <Select
                value={selectedCategoryId}
                onChange={(e) => setSelectedCategoryId(e.target.value)}
                label="Catégorie"
              >
                <MenuItem value="">
                  <em>Sélectionner une Catégorie</em>
                </MenuItem>
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button variant="contained" color="primary" onClick={handleUpdateUnderCategory}>
              Mettre à Jour
            </Button>
            <Button variant="outlined" color="secondary" onClick={() => setUnderCategoryToEdit(null)}>
              Annuler
            </Button>
          </Box>
        </Box>
      )}

      <Box>
        <Typography variant="h6" component="h3">
          Sous-Catégories Existantes
        </Typography>
        <List>
          {underCategories.map((underCategory) => (
            <ListItem key={underCategory.id} divider>
              <ListItemText
                primary={`${underCategory.name} (Catégorie : ${underCategory.category_name})`}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="edit"
                  color="primary"
                  onClick={() => handleEditUnderCategory(underCategory)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  color="secondary"
                  onClick={() => handleDeleteUnderCategory(underCategory.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default CategorieAdmin;
