import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { motion } from 'framer-motion';
import { getImages } from '../api';  // Import de l'instance `api`
const config = require('../config.json');

const GalleryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
`;

const ImageWrapper = styled.div`
  flex: 1 1 calc(33.333% - 10px);
  max-width: calc(33.333% - 10px);
  position: relative;
  cursor: pointer;

  @media (max-width: 1200px) {
    flex: 1 1 calc(50% - 10px);
    max-width: calc(50% - 10px);
  }

  @media (max-width: 800px) { 
    cursor: default;
    pointer-events: none;
  }

  @media (max-width: 768px) {
    flex: 1 1 calc(100% - 10px);
    max-width: calc(100% - 10px);
    height:auto;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const ModalContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-width: 100%;
  height: auto;
  margin: 20px;
`;

const ModalImage = styled(motion.img)`
  max-width: 90vw;  // Limite la largeur de l'image à 90% de la largeur de la fenêtre
  max-height: 90vh;  // Limite la hauteur de l'image à 90% de la hauteur de la fenêtre
  height: auto;
  object-fit: contain;

  @media (max-width: 768px) {
    max-width: 100%;
    max-height: 100%;
  }
`;

const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow: auto;  // Pour gérer les débordements dans les petites fenêtres
  padding: 20px;
  box-sizing: border-box;
`;

const ModalNavigation = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  color: white;
`;

const NavButton = styled.button`
  background: none;
  font-family: 'Oswald', sans-serif;
  border: none;
  color: #545454;
  font-size: 14px;
  cursor: pointer;
  padding: 0px 20px 30px 20px;
  margin-top: 20px;

  &:focus {
    outline: none;
  }
`;

const CloseButton = styled.button`
  font-family: 'Oswald', sans-serif;
  position: absolute;
  top: 10px;
  right: 50px;
  background: none;
  border: none;
  color: #545454;
  font-size: 14px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const SiteName = styled.h1`
  top: 10px;
  left: 0px;
  font-family: 'Oswald', sans-serif;
  font-size: 41px;
  text-transform: uppercase;
  display: flex;
  letter-spacing: .24em;
  position: relative;
  width: 100%;
  padding-top: 30px;
`;

const containerVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const itemVariants = {
  initial: { scale: 0.9, opacity: 0 },
  enter: { scale: 1, opacity: 1, transition: { duration: 0.4 } },
  exit: { scale: 0.9, opacity: 0, transition: { duration: 0.4 } },
};

const Gallery = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [images, setImages] = useState([]);

  useEffect(() => {
    Modal.setAppElement('#root');
   
    const fetchImages = async () => {
      try {
        const response = await getImages();  // Utilisez la fonction getImages de l'instance `api`
        console.log('Images fetched successfully:', response);  // Log les images récupérées
        const filteredImages = response.filter(image => image.thumb);
        console.log('Filtered images:', filteredImages);  // Log les images filtrées
        setImages(filteredImages);
      } catch (error) {
        console.error('Error fetching images:', error.response ? error.response.data : error.message);
      }
    };

    fetchImages();
  }, []);

  const openModal = (index) => {
    setCurrentIndex(index);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const goToNext = () => {
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  const goToPrevious = () => {
    setCurrentIndex((currentIndex - 1 + images.length) % images.length);
  };

  return (
    <>
      <GalleryContainer>
        {images.length > 0 && images.map((image, index) => (
          <ImageWrapper key={index} onClick={() => openModal(index)}>
            <StyledImage src={`${config.serverIP}/${image.thumb}`} alt={`Gallery image ${index + 1}`} />
          </ImageWrapper>
        ))}
      </GalleryContainer>
      
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={{ overlay: { background: 'rgba(0, 0, 0, 0.8)' }, content: { inset: 0, padding: 0, border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center' } }}
      >
        {images.length > 0 && (
          <ModalContainer>
            <ModalContent variants={containerVariants} initial="hidden" animate={isOpen ? "visible" : "hidden"} >
              <SiteName>Footage-now</SiteName>
              <ModalImage key={currentIndex} variants={itemVariants} initial="initial" animate={isOpen ? "enter" : "exit"} src={`${config.serverIP}/${images[currentIndex].name.replace("thumb-", "")}`} alt={`Gallery image ${currentIndex + 1}`} />
              <div>
                <NavButton onClick={goToPrevious}>Précédent</NavButton> |
                <NavButton onClick={goToNext}>Suivant</NavButton>
              </div>
            </ModalContent>
            
            <ModalNavigation>
              <NavButton onClick={goToPrevious}>&lt;</NavButton>
              <NavButton onClick={goToNext}>&gt;</NavButton>
            </ModalNavigation>
            
            <CloseButton onClick={closeModal}>X</CloseButton>
          </ModalContainer>
        )}
      </Modal>
    </>
  );
};

export default Gallery;
