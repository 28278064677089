import ReactGA from 'react-ga4';

const TRACKING_ID = "G-XBE2SFRB0H"; // Remplacez par votre ID de mesure GA4

export const initGA = () => {
  ReactGA.initialize(TRACKING_ID);
};

export const logPageView = (url) => {
  ReactGA.send({ hitType: 'pageview', page: url });
};
