import React, { useState } from 'react';
import { TextField, Button, Alert, Container, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth';
import { login } from '../api';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { isAuthenticated, setIsAuthenticated } = useAuth();
  const navigate = useNavigate();

  // V�rifier si l'utilisateur est d�j� authentifi� lors du chargement de la page
  // Si c'est le cas, rediriger automatiquement vers la page Admin
  if (isAuthenticated) {
    navigate('/admin/index');
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(username, password);
      setIsAuthenticated(true);
      setErrorMessage('');
      navigate('/admin/index');
    } catch (error) {
      setIsAuthenticated(false);
      setErrorMessage('Nom d\'utilisateur ou mot de passe invalide.');
    }
  };

  return (
    <Container maxWidth="xs">
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Login
        </Typography>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <TextField
          label="Nom d'utilisateur"
          variant="outlined"
          margin="normal"
          fullWidth
          required
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          label="Mot de passe"
          type="password"
          variant="outlined"
          margin="normal"
          fullWidth
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          Connexion
        </Button>
      </Box>
    </Container>
  );
};

export default Login;
