import React from 'react';
import { Container, Grid, Typography, Card, CardContent, CardMedia, Button, Divider, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const PrestaVideo = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {/* Section d'introduction */}
      <Box textAlign="center" mb={4}>
        <Typography variant="h4" component="h1" gutterBottom className="h1-no-margin">
          Réalisations Cinématographiques
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Des Projets Vidéo Clé en Main avec un Rendu Cinématographique
        </Typography>
        <Typography variant="body1" paragraph>
          Plongez dans une Expérience Immersive grâce à des Vidéos d'Exception. En tant que vidéaste de voyage passionné, je propose des services de captation et de post-production pour des vidéos au rendu cinématographique, prêtes à être mises en ligne. Mes réalisations vous permettent de présenter vos destinations de manière dynamique et immersive, suscitant l’envie de découvrir des lieux fascinants.
        </Typography>
      </Box>

      <Divider />

      {/* Pourquoi choisir */}
      <Box textAlign="center" my={4}>
        <Typography variant="h5" component="h2">
          Pourquoi Choisir Mes Services ?
        </Typography>
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardContent>
              <Typography variant="h5" component="h3">
                Immersion Visuelle
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Offrez à vos clients une expérience virtuelle immersive qui capture l'essence des destinations.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardContent>
              <Typography variant="h5" component="h3">
                Qualité Cinématographique
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Chaque vidéo est soigneusement montée pour créer un récit visuel captivant, avec des images en mouvement dynamiques et une bande sonore qui renforce l'impact émotionnel.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardContent>
              <Typography variant="h5" component="h3">
                Attrait pour les Voyages
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Des vidéos de qualité incitent les voyageurs à explorer de nouvelles destinations, augmentant l'attrait de vos offres.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Divider sx={{ my: 4 }} />

      {/* Nos Services de Réalisation Vidéo */}
      <Typography variant="h5" component="h2" align="center" gutterBottom>
        Mes Services de Réalisation Vidéo
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardMedia
              component="img"
              height="200"
              image="/assets/images/captation.jpg"
              alt="Captation d'Images"
            />
            <CardContent>
              <Typography variant="h5" component="h3">
                Captation d'Images
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Utilisation de drones stabilisés, drones FPV et équipements professionnels pour capturer des vues époustouflantes et diversifiées.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardMedia
              component="img"
              height="200"
              image="/assets/images/montage-video.jpg"
              alt="Post-Production"
            />
            <CardContent>
              <Typography variant="h5" component="h3">
                Post-Production
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Montage et traitement des images pour produire des vidéos rythmées et attrayantes, prêtes à transporter vos clients dans une aventure visuelle inoubliable.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Divider sx={{ my: 4 }} />

      {/* L'Importance d'un Rendu Cinématographique */}
      <Typography variant="h5" component="h2" align="center" gutterBottom>
        L'Importance d'un Rendu Cinématographique
      </Typography>
      <Typography variant="body1" paragraph align="center">
        Pour les agences de voyage et les offices de tourisme, l’utilisation de vidéos au rendu cinématographique est essentielle pour captiver l’imagination des voyageurs. Ces vidéos offrent une expérience immersive et authentique, incitant les spectateurs à explorer les merveilles du monde.
      </Typography>
      <Typography variant="body1" paragraph align="center">
        Contactez-moi dès aujourd'hui pour transformer vos projets vidéo en réalisations cinématographiques qui séduiront et inspireront vos clients à voyager.
      </Typography>

      <Divider sx={{ my: 4 }} />

      {/* Contact */}
      <Box textAlign="center" my={4}>
        <Typography variant="h5" component="h2" gutterBottom>
          Contactez-moi
        </Typography>
        <Typography variant="body1" paragraph>
          Contactez-moi dès aujourd'hui pour discuter de vos projets et voir comment mes services peuvent vous aider à atteindre vos objectifs. Ensemble, nous créerons des souvenirs visuels qui inciteront à l'exploration et à l'évasion.
        </Typography>
        <Button variant="contained" color="primary" component={Link} to="/contact">
          Me Contacter
        </Button>
      </Box>

      <Divider sx={{ my: 4 }} />

      <Typography variant="h6" component="h3" align="center" gutterBottom>
        Footage-Now - Capturer l'instant, inspirer le voyage.
      </Typography>
    </Container>
  );
};

export default PrestaVideo;
