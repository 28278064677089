import React from 'react';
import { Container, Grid, Typography, Card, CardContent, CardMedia, Button, Divider, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const PrestaDrone = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {/* Section d'introduction */}
      <Box textAlign="center" mb={4}>
        <Typography variant="h3" component="h1" gutterBottom className="h1-no-margin">
          Prestation Drone Stabilisé
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Des Prises de Vue Aériennes Précises et Spectaculaires
        </Typography>
        <Typography variant="body1" paragraph>
          Offrez-vous une Nouvelle Perspective avec nos Prestations en Drone Stabilisé. Découvrez l'art de la captation aérienne grâce à mes prestations de drone stabilisé. Que ce soit pour des projets commerciaux ou personnels, je vous propose des prises de vue aériennes uniques qui révèlent le monde sous un angle inédit.
        </Typography>
      </Box>

      <Divider />

      {/* Pourquoi choisir */}
      <Box textAlign="center" my={4}>
        <Typography variant="h5" component="h2">
          Pourquoi Opter pour un Drone Stabilisé ?
        </Typography>
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardContent>
              <Typography variant="h5" component="h3">
                Images Précises et Contemplatives
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Les drones stabilisés offrent des vues aériennes spectaculaires, parfaites pour des plans de type reportage.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardContent>
              <Typography variant="h5" component="h3">
                Séquences Fluides et Professionnelles
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Grâce à une technologie de stabilisation avancée, mes vidéos sont d'une qualité exceptionnelle, sans secousses ni tremblements.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardContent>
              <Typography variant="h5" component="h3">
                Nouvelle Perspective
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Transformez vos projets visuels avec des prises de vue aériennes qui captent l'essence des paysages et des structures.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Divider sx={{ my: 4 }} />

      {/* Nos Équipements de Pointe */}
      <Typography variant="h5" component="h2" align="center" gutterBottom>
        Des Équipements de Pointe
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardMedia
              component="img"
              height="200"
              image="/assets/images/mini-3-pro.jpg"
              alt="DJI Mini 3 Pro"
            />
            <CardContent>
              <Typography variant="h5" component="h3">
                DJI Mini 3 Pro
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Compact mais puissant, il est équipé d’un capteur de 1/1,3 pouces pour des images impressionnantes. Idéal pour des projets nécessitant mobilité et qualité.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardMedia
              component="img"
              height="200"
              image="/assets/images/dji-air-3.png"
              alt="DJI Air 3"
            />
            <CardContent>
              <Typography variant="h5" component="h3">
                DJI Air 3
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Doté d’un objectif grand angle et d’un téléobjectif, il capture des vidéos en 4K/100 ips avec des couleurs riches et authentiques grâce à l'enregistrement en D-LOG.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Divider sx={{ my: 4 }} />

      {/* Pour un Rendu Professionnel */}
      <Typography variant="h5" component="h2" align="center" gutterBottom>
        Pour un Rendu Professionnel
      </Typography>
      <Typography variant="body1" paragraph align="center">
        Depuis des années, les drones stabilisés sont des incontournables dans la création de contenus audiovisuels percutants. Que ce soit pour des vidéos promotionnelles ou des documentaires, ces équipements offrent une ampleur et une perspective inégalées, ajoutant une dimension artistique à vos projets.
      </Typography>
      <Typography variant="body1" paragraph align="center">
        Vous avez un projet ? Contactez-moi dès aujourd'hui pour discuter de vos besoins et créer une expérience visuelle inoubliable avec des prises de vue en drone stabilisé.
      </Typography>

      <Divider sx={{ my: 4 }} />

      {/* Contact */}
      <Box textAlign="center" my={4}>
        <Typography variant="h5" component="h2" gutterBottom>
          Contactez-moi
        </Typography>
        <Typography variant="body1" paragraph>
          Contactez-moi dès aujourd'hui pour discuter de vos projets et voir comment mes services peuvent vous aider à atteindre vos objectifs. Ensemble, nous créerons des souvenirs visuels qui inciteront à l'exploration et à l'évasion.
        </Typography>
        <Button variant="contained" color="primary" component={Link} to="/contact">
          Me Contacter
        </Button>
      </Box>

      <Divider sx={{ my: 4 }} />

      <Typography variant="h6" component="h3" align="center" gutterBottom>
        Footage-Now - Capturer l'instant, inspirer le voyage.
      </Typography>
    </Container>
  );
};

export default PrestaDrone;
