import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getImages, uploadImage, deleteImage } from '../api';
import Dropzone from 'react-dropzone';
import {
  Box,
  Typography,
  Button,
  Paper,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  Menu,
  MenuItem
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AccountCircle from '@mui/icons-material/AccountCircle';
import config from '../config.json';

const Admin = () => {
  const [images, setImages] = useState([]);
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageToDelete, setImageToDelete] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
      const fetchImages = async () => {
        try {
          const images = await getImages();
          setImages(images);
        } catch (error) {
          console.error("Error fetching images:", error);
        }
      };
      fetchImages();
    }, []);


  const handleDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
  };

  const handleUpload = async () => {
    setUploading(true);
  
    const promises = files.map(async (file) => {
      const formData = new FormData();
      formData.append('image', file);
  
      const config = {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        },
      };
  
      await uploadImage(formData, config);
    });
  
    await Promise.all(promises);
  
    const response = await getImages();
    console.log('Response from getImages after upload:', response); // Log the response for debugging
    
    // Updated conditional statement added below
    if ((response && Array.isArray(response)) || response === undefined) {
      setImages(response);
    } else {
      console.error('Unexpected response format:', response);
    }
    setFiles([]);
    setUploading(false);
    setProgress(0);
  };

  const handleDeleteClick = (image) => {
    setImageToDelete(image);
    setConfirmDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (imageToDelete) {
      await deleteImage(imageToDelete.name);
      const response = await getImages();
      console.log('Response from getImages after delete:', response); // Log the response for debugging
      if (response && Array.isArray(response)) {
        setImages(response);
      } else {
        console.error('Unexpected response format:', response);
      }
      setImageToDelete(null);
      setConfirmDialogOpen(false);
    }
  };

  const handleView = (image) => {
    setSelectedImage(image.name);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" component="h2" gutterBottom>
          Admin
        </Typography>
        <IconButton
          edge="end"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
          sx={{ paddingRight: '5px' }} // Add padding to the right
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem component={Link} to="/admin/youtube">Ajouter Lien YouTube</MenuItem>
          <MenuItem component={Link} to="/admin/settings">Paramètres</MenuItem>
          <MenuItem component={Link} to="/admin/account">Compte</MenuItem>
          <MenuItem component={Link} to="/admin/categories">Gestion des catégories</MenuItem>
          <MenuItem component={Link} to="/admin/clients">Gestion des clients</MenuItem>
          <MenuItem component={Link} to="/logout">Déconnexion</MenuItem>
        </Menu>
      </Box>
      <Dropzone onDrop={handleDrop} accept={{ 'image/*': [] }} multiple>
        {({ getRootProps, getInputProps }) => (
          <Paper
            {...getRootProps()}
            sx={{
              border: '2px dashed #ccc',
              padding: 4,
              textAlign: 'center',
              cursor: 'pointer',
              marginBottom: 2,
            }}
          >
            <input {...getInputProps()} />
            <Typography>Glisser et déposez quelques images ici, ou cliquez pour sélectionner des images</Typography>
          </Paper>
        )}
      </Dropzone>
      {files.length > 0 && (
        <Box>
          <Typography variant="h6" gutterBottom>
            Images sélectionnées:
          </Typography>
          <List>
            {files.map((file, index) => (
              <ListItem key={index}>
                <ListItemText primary={file.name} />
              </ListItem>
            ))}
          </List>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpload}
            disabled={uploading}
            sx={{ mt: 2 }}
          >
            Envoyer
          </Button>
          {uploading && (
            <Box sx={{ mt: 2 }}>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
          )}
        </Box>
      )}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" gutterBottom>
          Images téléchargées:
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {images.length > 0 ? (
            images.map((image, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  border: '1px solid #ccc',
                  borderRadius: 1,
                  padding: 1,
                }}
              >
                <img src={`${config.serverIP}/${image.thumb}`} alt={image.name} width="100" />
                <Typography>{image.name}</Typography>
                <Box>
                  <IconButton
                    aria-label={`view ${image.name}`}
                    onClick={() => handleView(image)}
                    sx={{ color: 'rgba(0, 0, 0, 0.54)' }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton
                    aria-label={`delete ${image.name}`}
                    onClick={() => handleDeleteClick(image)}
                    sx={{ color: 'rgba(0, 0, 0, 0.54)' }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            ))
          ) : (
            <Typography>Aucune image trouvée.</Typography>
          )}
        </Box>
      </Box>
      <Dialog open={!!selectedImage} onClose={handleClose}>
        <DialogTitle>Visualisation de l'image</DialogTitle>
        <DialogContent>
          <img src={`${config.serverIP}/${selectedImage}`} alt="Selected" style={{ width: '100%' }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <DialogTitle>Confirmation de suppression</DialogTitle>
        <DialogContent>
          <Typography>Supprimer cette image ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)} color="primary">
            Annuler
          </Button>
          <Button onClick={handleDeleteConfirm} color="secondary">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Admin;
