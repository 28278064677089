import React, { useState, useEffect } from 'react';
import { addYouTubeLink, getYouTubeLinks, deleteYouTubeLink } from '../api';
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Snackbar,
  Alert,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const Youtube = () => {
  const [link, setLink] = useState('');
  const [name, setName] = useState('');
  const [links, setLinks] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [linkToDelete, setLinkToDelete] = useState(null);

  useEffect(() => {
    const fetchLinks = async () => {
      try {
        const response = await getYouTubeLinks();
        if (response.data.success && Array.isArray(response.data.links)) {
          setLinks(response.data.links);
        } else {
          console.error('Réponse inattendue de l\'API:', response.data);
          setErrorMessage('Erreur lors de la récupération des liens YouTube.');
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des liens YouTube:', error);
        setErrorMessage('Erreur lors de la récupération des liens YouTube.');
      }
    };
    fetchLinks();
  }, []);

  const handleAddLink = async () => {
    if (!link || !name) {
      setErrorMessage('Veuillez entrer un nom et un lien YouTube.');
      return;
    }
    try {
      await addYouTubeLink(name, link);
      setSuccessMessage('Lien YouTube ajouté avec succès.');
      setLink('');
      setName('');
      const response = await getYouTubeLinks();
      if (response.data.success && Array.isArray(response.data.links)) {
        setLinks(response.data.links);
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout du lien YouTube:", error);
      setErrorMessage("Erreur lors de l'ajout du lien YouTube.");
    }
  };

  const handleOpenDialog = (linkId) => {
    setLinkToDelete(linkId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setLinkToDelete(null);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteYouTubeLink(linkToDelete);
      setSuccessMessage('Lien YouTube supprimé avec succès.');
      setLinkToDelete(null);
      setOpenDialog(false);
      const response = await getYouTubeLinks();
      if (response.data.success && Array.isArray(response.data.links)) {
        setLinks(response.data.links);
      }
    } catch (error) {
      console.error('Erreur lors de la suppression du lien YouTube:', error);
      setErrorMessage('Erreur lors de la suppression du lien YouTube.');
    }
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Paper sx={{ padding: 4, maxWidth: 600, margin: '0 auto' }}>
        <Typography variant="h5" component="h2" gutterBottom>
          Ajouter une vidéo YouTube
        </Typography>
        <TextField
          label="Nom de la vidéo YouTube"
          fullWidth
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          label="ID vidéo YouTube"
          fullWidth
          margin="normal"
          value={link}
          onChange={(e) => setLink(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
          onClick={handleAddLink}
        >
          Ajouter le lien
        </Button>
        {successMessage && (
          <Snackbar
            open={!!successMessage}
            autoHideDuration={6000}
            onClose={() => setSuccessMessage('')}
          >
            <Alert onClose={() => setSuccessMessage('')} severity="success">
              {successMessage}
            </Alert>
          </Snackbar>
        )}
        {errorMessage && (
          <Snackbar
            open={!!errorMessage}
            autoHideDuration={6000}
            onClose={() => setErrorMessage('')}
          >
            <Alert onClose={() => setErrorMessage('')} severity="error">
              {errorMessage}
            </Alert>
          </Snackbar>
        )}
      </Paper>
      <Paper sx={{ padding: 4, maxWidth: 600, margin: '20px auto' }}>
        <Typography variant="h5" component="h2" gutterBottom>
          Liste des liens YouTube
        </Typography>
        <List>
          {links.map((link) => (
            <ListItem
              key={link.id}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleOpenDialog(link.id)}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemText primary={link.name} secondary={link.link} />
            </ListItem>
          ))}
        </List>
      </Paper>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>Êtes-vous sûr de vouloir supprimer ce lien YouTube ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Annuler
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Youtube;
