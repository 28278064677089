import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Paper, Switch, FormControlLabel } from '@mui/material';
import { getSettings, updateSettings } from '../api';

const Settings = () => {
  const [settings, setSettings] = useState({
    site_name: '',
    admin_access: '',
    menu_list: '',
    maintenance: false
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await getSettings();
        if (response.data.success && response.data.settings) {
          // Convert maintenance to boolean
          setSettings({
            ...response.data.settings,
            maintenance: Boolean(response.data.settings.maintenance),
          });
        } else {
          setErrorMessage('Failed to fetch settings');
        }
      } catch (error) {
        setErrorMessage('Error fetching settings');
      }
    };
    fetchSettings();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings({
      ...settings,
      [name]: value
    });
  };

  const handleToggle = () => {
    setSettings({
      ...settings,
      maintenance: !settings.maintenance
    });
  };

  const handleSubmit = async () => {
    try {
      const response = await updateSettings(settings);
      if (response.data.success) {
        setSuccessMessage('Settings updated successfully');
      } else {
        setErrorMessage('Failed to update settings');
      }
    } catch (error) {
      setErrorMessage('Error updating settings');
    }
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Paper sx={{ padding: 4, maxWidth: 600, margin: '0 auto' }}>
        <Typography variant="h5" component="h2" gutterBottom>
          Site Settings
        </Typography>
        <TextField
          label="Site Name"
          fullWidth
          margin="normal"
          name="site_name"
          value={settings.site_name}
          onChange={handleChange}
        />
        <TextField
          label="Admin Access"
          fullWidth
          margin="normal"
          name="admin_access"
          value={settings.admin_access}
          onChange={handleChange}
        />
        <FormControlLabel
          control={<Switch checked={settings.maintenance} onChange={handleToggle} />}
          label="Maintenance Mode"
        />
        <Button variant="contained" color="primary" fullWidth sx={{ mt: 2 }} onClick={handleSubmit}>
          Save Settings
        </Button>
        {successMessage && (
          <Typography variant="body1" color="success" sx={{ mt: 2 }}>
            {successMessage}
          </Typography>
        )}
        {errorMessage && (
          <Typography variant="body1" color="error" sx={{ mt: 2 }}>
            {errorMessage}
          </Typography>
        )}
      </Paper>
    </Box>
  );
};

export default Settings;
