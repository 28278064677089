import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { Global, css } from '@emotion/react';
import { styled } from '@mui/system';

// Ajout des styles globaux pour la balise body et root
const GlobalStyles = () => (
  <Global
    styles={css`
      body {
        margin: 0;
        padding: 0;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        font-family: 'Oswald, sans-serif';
      }
      #root {
        margin: 0;
        padding: 0;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
      }
    `}
  />
);

const BackgroundBox = styled(Box)({
  position: 'relative',
  width: '100vw',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: `url('./assets/mt-background.jpg')`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  padding: 0,
});

const Overlay = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Couleur noire avec opacit� de 50%
  zIndex: 1,
});

const Content = styled(Box)({
  position: 'relative',
  zIndex: 2,
  textAlign: 'center',
  color: 'white',
});

const Maintenance = () => {
  return (
    <>
      <GlobalStyles />
      <BackgroundBox>
        <Overlay />
        <Content>
          <Typography variant="h2" component="h1" gutterBottom>
            FOOTAGE-NOW
          </Typography>
          <Typography variant="h3" component="h2" gutterBottom>
            Le site est actuellement en maintenance.
          </Typography>
          <Typography variant="h3" component="h2" gutterBottom>
            Revenez plus tard.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Suivez-nous sur :
          </Typography>
          <Box>
            <Link href="https://www.youtube.com/@footage-now" color="white" underline="hover" sx={{ marginRight: 2 }}>
              YouTube
            </Link>
            <Link href="https://www.instagram.com/sebastien_fantinati" color="white" underline="hover">
              Instagram
            </Link>
          </Box>
        </Content>
      </BackgroundBox>
    </>
  );
};

export default Maintenance;
