// src/components/Footer.js
import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: white;
  padding: 20px 0;
  text-align: center;
  font-family: 'Atrament-web', sans-serif;

  @media (max-width: 768px) {
    padding: 15px 0;
  }

  @media (max-width: 480px) {
    padding: 10px 0;
  }
`;

const FooterText = styled.p`
  margin: 5px 0;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  img {
    width: 24px;
    height: 24px;

    @media (max-width: 768px) {
      width: 20px;
      height: 20px;
    }

    @media (max-width: 480px) {
      width: 16px;
      height: 16px;
    }
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterText>© Sébastien Fantinati 2024</FooterText>
      <FooterText>Contact: sebastien@footage-now.video</FooterText>
      <div>
        <SocialIcons>
          <a href="https://www.instagram.com/seb_footagenow" rel="author" target="_blank">
            <img src="/assets/black_instagram.jpg" alt="Instagram" />
          </a>
          <a href="https://www.youtube.com/@footage-now" rel="author" target="_blank">
            <img src="/assets/black_youtube.jpg" alt="YouTube" />
          </a>
        </SocialIcons>
      </div>
    </FooterContainer>
  );
};

export default Footer;

